import React from 'react';
import * as Sentry from '@sentry/nextjs';
import NextErrorComponent, { ErrorProps } from 'next/error';
interface AppErrorProps extends ErrorProps {
  err?: Error;
  hasGetInitialPropsRun?: boolean;
}
function Error({
  statusCode,
  hasGetInitialPropsRun,
  err
}: AppErrorProps) {
  if (!hasGetInitialPropsRun && err) {
    Sentry.captureException(err);
  }
  return <NextErrorComponent statusCode={statusCode} />;
}
Error.getInitialProps = async ({
  res,
  err
}: {
  res: any;
  err: any;
}) => {
  if (err) {
    Sentry.captureException(err);
    await Sentry.flush(2000);
  }
  if (res?.statusCode) {
    return {
      statusCode: res.statusCode
    };
  }
  if (err?.statusCode) {
    return {
      statusCode: err.statusCode
    };
  }
  return {
    statusCode: '404'
  };
};
export default Error;